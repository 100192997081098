/*
 * Date: 2024
 * Description: New Claim form
 * Author: Philippe Leroux @ skitsc
 */

import { Box ,Grid, IconButton, TextField, Typography} from "@mui/material"
import { ReactElement , useState } from "react"
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
//Interfaces && types
import { i_initial_props } from "../interfaces/utility.interface"

const NewClaim = ( props : i_initial_props ) : ReactElement => {

    const [ step , setStep ] = useState<number>(1)
    const [ accepted_conditions , setAcceptedConditions ] = useState<boolean>(false)
    const red = "#ed6168"
    const top_title = "Prise d'information - Réclamation Assurance"
    const top_text = "Bienvenue chez Carrosserie Auto Prestige! Merci de remplir ce formulaire avec vos coordonnées, pièce d'identité et informations d'assurance. Ces données nous aideront à traiter votre dossier rapidement et en toute sécurité."
    const sub_title = { fontSize : 14 , fontWeight : 600 , color : '#595959' }
    const sub_text = { fontSize : 12 , color : '#595959' }
    const M : boolean = props.mobile
    const handleStep = () => {
        if(step === 1){
            if(accepted_conditions){

            }else{

            }
        }
    }
    return(
        <Box>
        <Box sx={{ minHeight : '96vh', backgroundImage : 'url(./images/bg_claim.jpg)' , backgroundSize : 'cover'}}>
            <Grid container p={ M ? 1 : 4}>
                <Grid item lg={5} md={6} xs={12} sx={{ display : 'flex' , justifyContent : 'center'}}>
                    <Box component={'img'} sx={{ width : '100%' , maxHeight : M ? '60vh' : '100vh' , maxWidth : '650px'}} src={'./images/claim_banner.jpg'}></Box>
                </Grid>
                <Grid item lg={7} md={6} xs={12} mt={2} sx={{display : 'flex' , justifyContent : 'center' ,}}>
                    <Box sx={{ maxWidth : '800px'}}>
                        {/* Claim form */} 
                        {/* ... */}
                        <Box>
                            <Typography sx={{ color : red , fontSize : 28}} mb={2}>{top_title}</Typography>
                            <Typography sx={{ fontSize : 20 , color : '#595959' }}>{top_text}</Typography>
                            <Box mb={4}>

                            </Box>
                            <Typography sx={{ fontSize : 24}} mb={2}>Termes et Conditions *</Typography>
                            <Typography sx={sub_title}>Termes et Conditions</Typography>
                            <Typography sx={sub_text} mb={2}>En remplissant ce formulaire, vous acceptez que Carrosserie Auto Prestige recueille, utilise et conserve vos renseignements personnels,
                                incluant vos coordonnées, votre photo du visage, pièce d'identité et informations d'assurance, dans le but de traiter votre demande de service. Une photo est prise de vous lors de l'envoie des document. 
                            </Typography>
                            <Typography sx={sub_title}>Confidentialité :</Typography>
                            <Typography sx={sub_text} mb={2}>Vos informations personnelles seront traitées de manière confidentielle et ne seront partagées qu'avec des tiers nécessaires à la prestation de nos services (ex. : compagnies d'assurances).
                                Nous nous engageons à respecter les lois applicables en matière de protection des renseignements personnels, incluant la Loi sur la protection des renseignements personnels dans le secteur privé.
                            </Typography>
                            <Typography sx={sub_title}>Utilisation des données</Typography>
                            <Typography sx={sub_text} mb={2}>
                            Les informations fournies seront utilisées pour ouvrir et gérer votre dossier, vous contacter, et faciliter la gestion des réclamations et réparations. 
                            Nous ne collectons que les données nécessaires et ne les utilisons à aucune autre fin sans votre consentement.
                            </Typography>
                            <Typography sx={sub_title}>Droit d'accès et de rectification :</Typography>
                            <Typography sx={sub_text} mb={2}>
                            Conformément à la loi, vous avez le droit de consulter, corriger ou supprimer vos informations personnelles en nous contactant à l'adresse : info@carrosserieautoprestige.com. 
                            Nous nous réservons le droit de refuser la modification ou suppression de certains renseignements pour des raisons légales ou administratives.
                            </Typography>
                            <Typography sx={sub_title}>Sécurité des données :</Typography>
                            <Typography sx={sub_text} mb={2}>
                            Nous mettons en place des mesures de sécurité adaptées pour protéger vos données contre les pertes, les accès non autorisés, ou toute utilisation abusive.
                            </Typography>
                            <Typography sx={sub_text}>
                            En soumettant ce formulaire, vous acceptez ces conditions et consentez au traitement de vos informations par Carrosserie Auto Prestige.
                            </Typography>
                            <hr style={{ color : red , width : '100%' , marginBottom : '2vh'}}></hr>
                            <Box sx={{ display : 'flex' , marginBottom : '2vh'}}>
                                <TextField type={'checkbox'} value={accepted_conditions} onChange={() => setAcceptedConditions(accepted_conditions ? false : true)} sx={{ width : '25px' , marginTop : '1px'}}></TextField>
                                <Typography sx={{ fontSize : 20 , color : red , marginLeft : 3 }} >J'accepte les conditions et conditions d'utilisation</Typography>
                            </Box>
                            <Box sx={{ display : 'flex' , justifyContent : 'flex-end' , cursor : 'pointer'}} onClick={() => handleStep()}>
                                <Typography sx={{ alignSelf : 'center' , color : red}}>Suivant</Typography>
                                <IconButton sx={{ }}>
                                    <NavigateNextIcon sx={{ color : red , fontSize : 30}}/>
                                </IconButton>
                            </Box>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </Box>
            <Box sx={{ width : '100%' , borderTop: '1px solid gray' , padding : '12px' , backgroundColor : '#595959' , minHeight : '4vh'}}>
                <Typography sx={{ color : 'white' , fontSize : 14 , textAlign : 'center'}}>Ne soumettez pas d'informations confidentielles telles que des détails de carte de crédit, des codes PIN de téléphone mobile et de distributeur automatique, des OTP, des mots de passe de compte, etc.</Typography>
            </Box>
        </Box>
    )
}


export default NewClaim