/*
 * Date: 2024
 * Description: New components more flexible for uploads and image previews
 * Author: Philippe Leroux @ Skitsc
 */


//Modules
import React, { useState , forwardRef } from 'react';
import { Box, IconButton, Typography } from '@mui/material';
import { ArrowForward, ArrowBack } from '@mui/icons-material';

// Utils
import { setUrl } from '../../../utils/utility';
import { modal_style } from '../../../styles/main.styles';
import { i_modal_full_view_props } from '../../../interfaces/utility.interface';

//Components
import CloseModal from '../utils/close.modal';

const InnerModalView = forwardRef((props : i_modal_full_view_props , ref) => {
    const combinedImages = [
        ...props.assets.map((img) => ({ type: 'assets', src: props.url + img })),
        ...props.uploads.map((img) => ({ type: 'uploads', src: setUrl(img) })),
    ];

    const [currentIndex, setCurrentIndex] = useState(props.initial_index || 0);

    // Navigation functions
    const goNext = () => {
        if (currentIndex < combinedImages.length - 1) setCurrentIndex(currentIndex + 1);
    };

    const goPrev = () => {
        if (currentIndex > 0) setCurrentIndex(currentIndex - 1);
    };

    // Get the current image source
    const currentImage = combinedImages[currentIndex];
    return (
        <Box sx={[modal_style , { width : 'auto' , padding : 6 , maxWidth : '1400px'}]}>
            <Box sx={{ marginBottom: '2vh' }}>
                <CloseModal handleClose={props.handleClose} />
                <Typography>{props.title}</Typography>
            </Box>
            <Box sx={{ position: 'relative' }}>
                {currentIndex !== 0  &&
                    <IconButton onClick={goPrev} sx={{ position: 'absolute', top: '50%', zIndex: 10 , left: '-45px' }}>
                        <ArrowBack />
                    </IconButton>
                }
                <Box component={'img'} src={currentImage.src} sx={{ width: '100%', height: 'auto', maxHeight: '90vh', objectFit: 'contain' }} alt={`Image ${currentIndex + 1}`}/>
                {currentIndex + 1 < combinedImages.length  &&
                    <IconButton onClick={goNext} sx={{ position: 'absolute', top: '50%', right: '-45px', zIndex: 10 }}>
                        <ArrowForward />
                    </IconButton>
                }
            </Box>
        </Box>
    );
});

export default InnerModalView;
