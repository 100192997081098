/*
 * Date: 2024
 * Description: Customer inputs form reworked..
 * Author: Philippe Leroux @ Skitsc
 */

//Modules
import { ReactElement, ChangeEvent } from "react";
import { Box, Typography, MenuItem, TextField, Grid , Button } from "@mui/material";
import { LoadingButton } from "@mui/lab";
//Components
import ImportantDetails from "../../customers/form/important.details";
import LicenceInputs from "../../customers/form/licence.input";
import CustomerContacts from "../../customers/form/contacts.inputs";
import CustomerAddress from "../../customers/form/customer.address";
import CarInputs from "../../cars/car.inputs";
import RegInputs from "../../cars/registration/registration.inputs";
import InsurancesInputs from "../../cars/insurance/insurance.inputs";
//Interfaces && types
import { i_customer_car_form_props, i_customer_form_props , i_customer_car_insurance_props , i_customer_car_registration_props } from "../../../interfaces/customer.interface";

//Styles
import { bottom_line, input_base, sub_title } from "../../../styles/form.styles";


interface i_combined_props  {
    props : i_customer_form_props,
    car_props : i_customer_car_form_props,
    insurance_props : i_customer_car_insurance_props,
    register_props : i_customer_car_registration_props
    step : number,
    handleStep : (type: 'next' | 'prev') => void
}

const CustomerClaimInputs = ( { props , car_props , register_props , insurance_props , step , handleStep } : i_combined_props) : ReactElement => {
    const F = props.form;
    const E = props.errors;
    const handleChange = (e : ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target
        props.setForm({...F, [name] : value})
    }
    const V = props.view
    const container_grid ={ border : '1px solid #333' , borderTopLeftRadius:'8px' , borderTopRightRadius: '8px' , maxWidth : '1400px' ,  marginLeft : 'auto' , marginRight: 'auto'}
  return (
    <Box component={"form"} p={2} m={0} sx={{ marginBottom : '2vh'}} id={'customer_inputs'} >
        {(step === 0 || step === 6) &&
        <Grid container p={2} sx={container_grid}>
            <Grid item xs={3}>
                <Typography sx={sub_title}>Customer type*</Typography>
            </Grid>
            <Grid item xs={9}>
                { V ?
                <Typography sx={{ fontSize : 20}}>{F.customer_type}</Typography>
                :
                <TextField select value={F.customer_type} name={'customer_type'} size="small" sx={[input_base , { width : '300px'} ]} onChange={handleChange}
                error={E.customer_type !== ''} helperText={E.customer_type} autoComplete={props.dev ? "off" : "new-password" }>
                    <MenuItem value={'Individual'}>Individual</MenuItem>
                    <MenuItem value={'Business'}>Business</MenuItem>
                </TextField>
                }
            </Grid>
            <Grid item xs={12} sx={{ marginBottom : '1vh'}}> <hr style={bottom_line}/></Grid>
            <Grid item xs={3}>
                <Typography sx={sub_title}>Name *</Typography>
            </Grid>
                {V ? 
                <Grid item xs={9} sx={{display : 'flex'}}>
                 <Typography sx={{ marginRight : 1}}> {F.first_name} </Typography>
                 {F.middle_name !== '' &&
                    <Typography sx={{ marginRight : 1}}> {F.middle_name}</Typography>
                  }
                 <Typography sx={{ marginRight : 1}}> {F.last_name} </Typography>
                </Grid>
            :
            <Grid item xs={9}>
                <TextField sx={[input_base , { width : '200px'} ]} name={'first_name'} disabled={V} size="small" placeholder={"First name"} value={F.first_name} error={E.first_name !== ""} helperText={E.first_name}
                onChange={handleChange} autoComplete={props.dev ? "off" : "new-password" }/>  
                <TextField sx={[input_base , { width : '200px'} ]} name={'middle_name'} disabled={V} size="small" placeholder={"Middle name"} value={F.middle_name} error={E.middle_name !== ""} helperText={E.middle_name}
                onChange={handleChange} autoComplete={props.dev ? "off" : "new-password" }/>                 
                <TextField sx={[input_base , { width : '200px'} ]} name={'last_name'} disabled={V} size="small" placeholder={"Last name"} value={F.last_name} error={E.last_name !== ''} helperText={E.last_name}
                onChange={handleChange} autoComplete={props.dev ? "off" : "new-password" }/>
            </Grid>
            }

            <Grid item xs={12} sx={{ marginBottom : '1vh'}}> <hr style={bottom_line}/></Grid>
            <Grid item xs={3}>
                <Typography sx={sub_title}>Email address* </Typography>
            </Grid>
            <Grid item xs={9}>
                { V ? 
                <Typography sx={{ fontSize : 20}}>{F.email}</Typography>
                :
                <TextField type={'email'} name={'email'} disabled={V} sx={[input_base , { width : '300px'} ]} size="small" value={F.email} error={E.email!== ''} helperText={E.email}
                onChange={handleChange} autoComplete={props.dev ? "off" : "new-password" }/>
                }
            </Grid>
            <Grid item xs={12} sx={{ marginBottom : '1vh'}}> <hr style={bottom_line}/></Grid>
            <Grid item xs={3}>
                <Typography sx={sub_title}>Phone number* </Typography>
            </Grid>  
            <Grid item xs={9}>
                { V ?
                <Typography sx={{ fontSize : 20}}>{F.phone_one}</Typography>
                :
                <TextField sx={[input_base , { width : '300px'} ]} disabled={V} name={'phone_one'} size="small" value={F.phone_one} onChange={handleChange}
                    error={E.phone_one !== ''} helperText={E.phone_one}autoComplete={props.dev ? "off" : "new-password" }/>
                }
            </Grid>
            
            { V  && F.phone_two === "" ?
            <></>
            :
            <>
            <Grid item xs={12} sx={{ marginBottom : '1vh'}}> <hr style={bottom_line}/></Grid>
            <Grid item xs={3}>
                <Typography sx={sub_title}>Additional phone number</Typography>
            </Grid>
              <Grid item xs={9}>
              { V ?
                  <Typography sx={{ fontSize : 20}}>{F.phone_two}</Typography>
                  :
                  <TextField sx={[input_base , { width : '300px'} ]} disabled={V} name={'phone_two'} size="small" value={F.phone_two} onChange={handleChange}
                  error={E.phone_two !== ''} helperText={E.phone_two} autoComplete={props.dev ? "off" : "new-password" }/>
              }
              </Grid>
              </>
            }
          
            <Grid item xs={12} sx={{ marginBottom : '1vh'}}> <hr style={bottom_line}/></Grid>
            <CustomerAddress {...props} />
            <ImportantDetails {...props} />
            { V ?
                <> 
                    { F.notes === "" ?
                    <>
                        <Grid item xs={3} sx={sub_title}>Notes</Grid>
                        <Grid item xs={9} sx={{marginBottom : '1vh'}}>
                            <Typography sx={{}}>Nothing</Typography>
                        </Grid>
                    </>
                    :
                    <>
                        <Grid item xs={3}>
                            <Typography sx={sub_title}>Notes</Typography>
                        </Grid>
                        <Grid item xs={9} sx={{marginBottom : '1vh'}}>
                            <Typography sx={{ fontSize : 16}}>{F.notes}</Typography>
                        </Grid>
                    </>
                    }   
                </>
            :
            <>
                <Grid item xs={3}>
                    <Typography sx={sub_title}>Add your notes</Typography>
                </Grid>
                <Grid item xs={9} sx={{marginBottom : '1vh'}}>
                    <TextField sx={[input_base, { maxWidth : '700px' } ]} disabled={V} name="notes" fullWidth multiline minRows={3} value={F.notes} onChange={handleChange}
                        error={E.notes!== ''} placeholder="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, " helperText={E.notes}autoComplete={props.dev? "off" : "new-password" }/>
                </Grid>
            </>
            }
        </Grid>
        }
        {(step === 1 || step === 6) &&
            <Grid container p={2} mt={2} sx={container_grid}>
                
                <CustomerContacts {...props} />
            </Grid>
        }
        {(step === 2 || step === 6) &&
            <Grid container mt={2} p={2} sx={container_grid}>
                <Grid item xs={12}>
                 <LicenceInputs {...props} />
                </Grid>
            </Grid>
           
        }
        {(step === 3 || step === 6) &&
         <Grid container p={2} mt={2} sx={container_grid}>
         <Grid item xs={12}> 
             <CarInputs {...car_props} />
         </Grid>
     </Grid>

        }
        {(step === 4 || step === 6) &&
                    <Grid container p={2} mt={2} sx={container_grid}>
                    <Box> <Typography sx={{ fontSize : 20 , fontWeight : 600}}>Car registration </Typography></Box>
                    <RegInputs {...register_props} />
                </Grid>
        }
        {(step === 5 || step === 6) &&
            <Grid container p={2} mt={2} sx={container_grid}>
                <Box> <Typography sx={{ fontSize : 20 , fontWeight : 600}}>Car Insurance </Typography></Box>
                <InsurancesInputs {...insurance_props} />
            </Grid>
        }

        <Grid container>
        <Grid item xs={3}></Grid>
        <Grid item xs={6} sx={{ marginTop : '1vh' ,display : 'flex' , justifyContent : 'center'}}>
            {step > 0 &&
                <Button onClick={() => handleStep('prev')} sx={{ marginRight : '1vh' , backgroundColor : 'white' , color : 'black' , border : '1px solid gray'}}>Prev</Button>
            }
            {step === 0 &&
                <Button onClick={() => props.setDisplay('table')} sx={{ marginRight : '1vh' , backgroundColor : 'white' , color : 'black' , border : '1px solid gray'}} variant="contained" color="primary">Cancel</Button>
            }
            {step < 6 &&
                <Button onClick={() => handleStep('next')} variant="contained" color="primary" sx={{fontWeight : 600 }}>Next</Button>
            }
            {step === 6 && 
                <LoadingButton onClick={() => props.callback()} loading={props.loading} variant="contained" color="primary" sx={{fontWeight : 600}}>Not finished yet</LoadingButton>
            }
        </Grid>
            <Grid item xs={3}></Grid>
        </Grid>
    </Box>
  );
}
export default CustomerClaimInputs