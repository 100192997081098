

import { m_validate_email , m_validate_password , m_validate_empty_fields, m_validate_str,  m_validate_colors ,  m_validate_phone, m_validate_opt_phone, m_validate_customer_type, m_validate_customer_title , m_validate_tax_rates , m_validate_tax_type , m_validate_currency ,
    m_validate_payment_condition , m_validate_language , m_validate_zip_code , m_validate_contact_arr, m_validate_opt_zip_code, m_validate_number, m_validate_email_opt, m_validate_hex_colors, m_validate_url_opt, m_validate_task_state, m_validate_task_type, m_validate_select,
     m_validate_opt_nas, m_validate_user_type, m_validate_skill_code, m_validate_password_opt,
     m_validate_string_arr} from "./utility.middleware";

import { lexique_message } from "../utils/constant";

import { i_user_rules } from "../interfaces/user.interface";
import { i_customer_rules , i_contacts_rules , i_customer_car_rules , i_customer_car_registration_rules , i_customer_car_insurance_rules, i_customer_licence, i_customer_licence_rules } from "../interfaces/customer.interface";
import { i_app_config_rules } from "../interfaces/setting.interface";
import { i_planner_rules } from "../interfaces/planner.interface";
import { i_job_rules } from "../interfaces/jobs.interface";
import { i_task_rules } from "../interfaces/task.interface";
import { i_claim_rules } from "../interfaces/claim.interface";

const customer_rules_completed : i_customer_rules[] = [
    { field : "first_name", validate : m_validate_empty_fields, error : lexique_message.empty_error},
    { field : "first_name", validate : m_validate_str, error : lexique_message.special_error },
    { field : "middle_name", validate : m_validate_str, error : lexique_message.special_error},
    { field : "last_name", validate : m_validate_empty_fields, error : lexique_message.empty_error },
    { field : "last_name", validate : m_validate_str, error : lexique_message.special_error },
    { field : "customer_type" , validate : m_validate_customer_type, error : lexique_message.customer_type_error },
    { field : "email", validate : m_validate_email, error : lexique_message.email_error },
    { field : "title", validate : m_validate_customer_title, error : lexique_message.customer_title_error },
    { field : "phone_one", validate : m_validate_phone, error : lexique_message.phone_error },
    { field : "phone_two", validate : m_validate_opt_phone, error : lexique_message.phone_error },
    { field : "company_name", validate : m_validate_str, error : lexique_message.special_error },
    { field : "tax_preference", validate : m_validate_tax_type, error : lexique_message.tax_type_error },
    { field : "tax_rate", validate : m_validate_tax_rates, error : lexique_message.tax_rates_error },
    { field : "currency", validate : m_validate_currency, error : lexique_message.currency_error },
    { field : "payment_terms", validate : m_validate_payment_condition, error : lexique_message.payment_terms_error },
    { field : "portal_language", validate : m_validate_language, error : lexique_message.portal_language_error },
    { field : "unit_number", validate : m_validate_str, error : lexique_message.special_error },
    { field : "door_number" , validate : m_validate_empty_fields , error : lexique_message.empty_error },
    { field : "door_number", validate : m_validate_str, error : lexique_message.special_error },
    { field : "street", validate : m_validate_empty_fields, error : lexique_message.empty_error },
    { field : "street", validate : m_validate_str, error : lexique_message.special_error },
    { field : "city", validate : m_validate_empty_fields, error : lexique_message.empty_error },
    { field : "city", validate : m_validate_str, error : lexique_message.special_error },
    { field : "state", validate : m_validate_empty_fields, error : lexique_message.empty_error },
    { field : "state", validate : m_validate_str, error : lexique_message.special_error },
    { field : "zip", validate : m_validate_zip_code, error : lexique_message.zip_error },
    { field : "contact_array", validate : m_validate_contact_arr , error : lexique_message.contact_arr_error },
    { field : "welcome_email", validate : m_validate_str, error : lexique_message.empty_error },
    // { field : "notes" , validate : m_validate_str, error : lexique_message.special_error}
]
const customer_rules : i_customer_rules[] = [
    { field : "first_name", validate : m_validate_empty_fields, error : lexique_message.empty_error},
    { field : "first_name", validate : m_validate_str, error : lexique_message.special_error },
    { field : "middle_name", validate : m_validate_str, error : lexique_message.special_error},
    { field : "last_name", validate : m_validate_empty_fields, error : lexique_message.empty_error },
    { field : "last_name", validate : m_validate_str, error : lexique_message.special_error },
    { field : "customer_type" , validate : m_validate_customer_type, error : lexique_message.customer_type_error },
    { field : "email", validate : m_validate_email, error : lexique_message.email_error },
    { field : "title", validate : m_validate_customer_title, error : lexique_message.customer_title_error },
    { field : "phone_one", validate : m_validate_phone, error : lexique_message.phone_error },
    { field : "phone_two", validate : m_validate_opt_phone, error : lexique_message.phone_error },
    { field : "company_name", validate : m_validate_str, error : lexique_message.special_error },
    { field : "tax_preference", validate : m_validate_tax_type, error : lexique_message.tax_type_error },
    { field : "tax_rate", validate : m_validate_tax_rates, error : lexique_message.tax_rates_error },
    { field : "currency", validate : m_validate_currency, error : lexique_message.currency_error },
    { field : "payment_terms", validate : m_validate_payment_condition, error : lexique_message.payment_terms_error },
    { field : "portal_language", validate : m_validate_language, error : lexique_message.portal_language_error },
    { field : "unit_number", validate : m_validate_str, error : lexique_message.special_error },
    { field : "door_number", validate : m_validate_str, error : lexique_message.special_error },
    { field : "street", validate : m_validate_str, error : lexique_message.special_error },
    { field : "city", validate : m_validate_str, error : lexique_message.special_error },
    { field : "state", validate : m_validate_str, error : lexique_message.special_error },
    { field : "zip", validate : m_validate_opt_zip_code, error : lexique_message.zip_error },
    { field : "contact_array", validate : m_validate_contact_arr , error : lexique_message.contact_arr_error },
    { field : "welcome_email", validate : m_validate_str, error : lexique_message.empty_error },
    // { field : "notes" , validate : m_validate_str, error : lexique_message.special_error}
]
const contact_rules : i_contacts_rules[] = [
    { field : 'designation', validate : m_validate_empty_fields, error : lexique_message.empty_error},
    { field : 'designation', validate : m_validate_str, error : lexique_message.special_error },
    { field : 'first_name', validate : m_validate_empty_fields, error : lexique_message.empty_error },
    { field : 'first_name', validate : m_validate_str, error : lexique_message.special_error },
    { field : 'last_name', validate : m_validate_empty_fields, error : lexique_message.empty_error},
    { field : 'last_name', validate : m_validate_str, error : lexique_message.special_error },
    { field : 'email', validate : m_validate_email, error : lexique_message.email_error },
    { field : 'phone', validate : m_validate_phone, error : lexique_message.phone_error },
    { field : 'mobile_phone' , validate : m_validate_opt_phone, error : lexique_message.phone_error },
    { field : "unit_number", validate : m_validate_str, error : lexique_message.special_error },
    { field : "door_number", validate : m_validate_str, error : lexique_message.special_error },
    { field : "street", validate : m_validate_str, error : lexique_message.special_error },
    { field : "city", validate : m_validate_str, error : lexique_message.special_error },
    { field : "state", validate : m_validate_str, error : lexique_message.special_error },
    { field : "zip", validate : m_validate_opt_zip_code, error : lexique_message.zip_error },
]
const config_rules : i_app_config_rules[] = [
    { field : 'name' , validate : m_validate_empty_fields, error : lexique_message.empty_error},
    { field : 'name' , validate : m_validate_str, error : lexique_message.special_error },
    { field : 'email' , validate : m_validate_email_opt, error : lexique_message.email_error },
    { field : 'url', validate : m_validate_url_opt, error : lexique_message.url_error},
    { field : 'id' , validate : m_validate_str, error : lexique_message.special_error },
    { field : 'country', validate : m_validate_empty_fields, error : lexique_message.empty_error},
    { field : 'country', validate : m_validate_str, error : lexique_message.special_error },
    { field : 'activity', validate : m_validate_empty_fields, error : lexique_message.empty_error},
    { field : 'activity', validate : m_validate_str, error : lexique_message.special_error },
    { field : 'phone' , validate : m_validate_opt_phone, error : lexique_message.phone_error },
    { field : 'phone_two', validate : m_validate_opt_phone, error : lexique_message.phone_error},
    { field : "unit_number", validate : m_validate_str, error : lexique_message.special_error },
    { field : "door_number", validate : m_validate_str, error : lexique_message.special_error },
    { field : "street", validate : m_validate_str, error : lexique_message.special_error },
    { field : "city", validate : m_validate_str, error : lexique_message.special_error },
    { field : "state", validate : m_validate_str, error : lexique_message.special_error },
    { field : "zip", validate : m_validate_opt_zip_code, error : lexique_message.zip_error },
    { field : "primary_color", validate : m_validate_hex_colors, error : lexique_message.color_error},
    { field : "secondary_color", validate : m_validate_hex_colors, error : lexique_message.color_error},
    { field : "selected_color", validate : m_validate_hex_colors, error : lexique_message.color_error},
    { field : "hover_color", validate : m_validate_hex_colors, error : lexique_message.color_error},
]
const profil_password_rules : i_user_rules[] = [
    { field : 'password' , validate : m_validate_password, error : lexique_message.password_error },
    { field : 'confirm_password' , validate : m_validate_password, error : lexique_message.password_error},
    { field : 'old_password' , validate : m_validate_password, error : lexique_message.password_error}
]
const profil_rules : i_user_rules[] = [
    { field : 'first_name', validate : m_validate_empty_fields , error : lexique_message.empty_error },
    { field : 'first_name', validate : m_validate_str, error : lexique_message.special_error },
    { field : 'last_name', validate : m_validate_empty_fields, error : lexique_message.empty_error},
    { field : 'last_name', validate : m_validate_str, error : lexique_message.special_error },
    { field : 'email', validate : m_validate_email, error : lexique_message.email_error },
    { field : 'phone_one', validate : m_validate_phone, error : lexique_message.phone_error },
    { field : 'phone_two', validate : m_validate_opt_phone, error : lexique_message.phone_error },
    { field : "unit_number", validate : m_validate_str, error : lexique_message.special_error },
    { field : "door_number", validate : m_validate_str, error : lexique_message.special_error },
    { field : "street", validate : m_validate_str, error : lexique_message.special_error },
    { field : "city", validate : m_validate_str, error : lexique_message.special_error },
    { field : "state", validate : m_validate_str, error : lexique_message.special_error },
    { field : "zip", validate : m_validate_opt_zip_code, error : lexique_message.zip_error },
]
const user_rules : i_user_rules[] = [
    { field : 'first_name', validate : m_validate_empty_fields , error : lexique_message.empty_error },
    { field : 'first_name', validate : m_validate_str, error : lexique_message.special_error },
    { field : 'last_name', validate : m_validate_empty_fields, error : lexique_message.empty_error},
    { field : 'last_name', validate : m_validate_str, error : lexique_message.special_error },
    { field : 'email', validate : m_validate_email, error : lexique_message.email_error },
    { field : "nas" , validate : m_validate_opt_nas , error : lexique_message.nas_error },
    { field : 'phone_one', validate : m_validate_phone, error : lexique_message.phone_error },
    { field : 'phone_two', validate : m_validate_opt_phone, error : lexique_message.phone_error },
    { field : 'password' , validate : m_validate_password_opt, error : lexique_message.password_error },
    { field : 'confirm_password' , validate : m_validate_password_opt, error : lexique_message.password_error},
    { field : "unit_number", validate : m_validate_str, error : lexique_message.special_error },
    { field : "door_number", validate : m_validate_str, error : lexique_message.special_error },
    { field : "street", validate : m_validate_str, error : lexique_message.special_error },
    { field : "city", validate : m_validate_str, error : lexique_message.special_error },
    { field : "state", validate : m_validate_str, error : lexique_message.special_error },
    { field : "zip", validate : m_validate_opt_zip_code, error : lexique_message.zip_error },
    { field : "type" , validate: m_validate_user_type , error : lexique_message.user_type_error},
    { field : "skill_code" , validate : m_validate_skill_code , error : lexique_message.skill_code_error},
    { field : "hour_rate" , validate : m_validate_number, error : lexique_message.numeric_error},
    { field : "start" , validate : m_validate_number , error : lexique_message.numeric_error},
    { field : "end" , validate : m_validate_number , error : lexique_message.numeric_error},
    { field : "code" , validate : m_validate_number, error : lexique_message.numeric_error},
    { field : "nip" , validate : m_validate_number, error : lexique_message.numeric_error},
]
const planner_rules : i_planner_rules[] = [
    { field: 'title', validate: m_validate_empty_fields, error: lexique_message.empty_error },
    { field: 'title', validate: m_validate_str, error: lexique_message.str_error },
    // { field: 'description', validate: m_validate_str, error: lexique_message.str_error },
    { field: 'start', validate: m_validate_number, error: lexique_message.date_error },
    { field: 'end', validate: m_validate_number, error: lexique_message.date_error },
    { field: 'color', validate: m_validate_colors, error: lexique_message.empty_error }
];
const car_rules : i_customer_car_rules[] = [
    { field : "year", validate : m_validate_empty_fields, error : lexique_message.empty_error},
    { field : "year", validate : m_validate_number , error : lexique_message.numeric_error},
    { field : "make", validate : m_validate_empty_fields, error : lexique_message.empty_error},
    { field : "make", validate : m_validate_str, error : lexique_message.special_error},
    { field : "model", validate : m_validate_empty_fields, error : lexique_message.empty_error},
    { field : "model", validate : m_validate_str, error : lexique_message.special_error },
    { field : "trim", validate : m_validate_empty_fields, error : lexique_message.empty_error},
    { field : "trim", validate : m_validate_str, error : lexique_message.special_error},
    { field : "color", validate : m_validate_empty_fields, error : lexique_message.empty_error },
    { field : "color", validate : m_validate_str, error : lexique_message.special_error },
    { field : "color_code", validate : m_validate_str, error : lexique_message.special_error },
    { field : "color_code_two", validate : m_validate_str, error : lexique_message.special_error },
    { field : "kilo", validate : m_validate_str, error : lexique_message.special_error},
    { field : "vin", validate : m_validate_empty_fields, error : lexique_message.empty_error},
    { field : "vin", validate : m_validate_str, error : lexique_message.special_error },
]
const registration_rules : i_customer_car_registration_rules[] = [
    { field : 'registration_number', validate : m_validate_empty_fields, error : lexique_message.empty_error },
    // { field : 'registration_number', validate : m_validate_str, error : lexique_message.str_error },
    { field : 'no_cert' , validate : m_validate_empty_fields, error : lexique_message.empty_error},
    { field : 'delivered' , validate : m_validate_number , error : lexique_message.date_error},
    { field : 'expires' , validate : m_validate_number, error : lexique_message.date_error},
    { field : 'path', validate : m_validate_string_arr, error : lexique_message.string_arr_error},
]
const insurances_rules : i_customer_car_insurance_rules[] = [
    { field : 'company_name', validate : m_validate_empty_fields, error : lexique_message.empty_error },
    { field : 'company_name', validate : m_validate_str, error : lexique_message.str_error },
    { field : 'no_police' , validate : m_validate_str, error : lexique_message.str_error},
    { field : 'no_police' , validate : m_validate_empty_fields, error : lexique_message.empty_error},
    { field : 'courtier' , validate : m_validate_str, error : lexique_message.str_error},
    { field : 'new_value_code' , validate : m_validate_str, error : lexique_message.str_error},
    { field : 'delivered' , validate : m_validate_number , error : lexique_message.date_error},
    { field : 'expires' , validate : m_validate_number, error : lexique_message.date_error},
    { field : 'path', validate : m_validate_string_arr, error : lexique_message.string_arr_error},
]
const licence_rules : i_customer_licence_rules[] = [
    { field : 'licence_number', validate : m_validate_empty_fields, error : lexique_message.empty_error},
    { field : 'licence_province', validate : m_validate_empty_fields, error : lexique_message.empty_error},
    { field : 'reference_number', validate : m_validate_empty_fields, error : lexique_message.empty_error},
    { field : 'expiration_date', validate : m_validate_empty_fields, error : lexique_message.empty_error},
    { field : 'delivery_date', validate : m_validate_empty_fields, error : lexique_message.empty_error},
    { field : 'path', validate : m_validate_string_arr, error : lexique_message.string_arr_error},
]
const job_rules : i_job_rules[] = [
    {field : "title", validate : m_validate_empty_fields, error : lexique_message.empty_error},
    // {field : "title", validate : m_validate_str, error : lexique_message.special_error },        
    {field : "order_number" , validate : m_validate_empty_fields, error : lexique_message.empty_error },
    {field : "order_number", validate : m_validate_str, error : lexique_message.special_error },
    {field : "claim_number" , validate : m_validate_str , error : lexique_message.special_error} ,
    {field : "user_id", validate : m_validate_select, error : lexique_message.select_error},
    // {field : "description", validate : m_validate_str, error : lexique_message.special_error },
    {field : "customer_id", validate : m_validate_select, error : lexique_message.select_error},
    {field : "car_id", validate : m_validate_select, error : lexique_message.select_error},
    {field : "status", validate : m_validate_select, error : lexique_message.select_error}
]
const task_rules : i_task_rules[] = [
    {field : "title", validate : m_validate_empty_fields, error : lexique_message.empty_error},
    // {field : "title", validate : m_validate_str, error : lexique_message.special_error },
    {field : "time_estimated", validate : m_validate_empty_fields, error : lexique_message.empty_error},
    {field : "time_estimated", validate : m_validate_number, error : lexique_message.numeric_error },
    {field : "user_id", validate : m_validate_select, error : lexique_message.empty_error},
    {field : "type", validate : m_validate_task_type, error : lexique_message.select_error},
    {field : "start", validate : m_validate_empty_fields, error : lexique_message.empty_error},
    {field : "end", validate : m_validate_empty_fields, error : lexique_message.empty_error},
    // {field : "description", validate : m_validate_str, error : lexique_message.special_error },
    {field : "note", validate : m_validate_str, error : lexique_message.special_error },
    {field : "status", validate : m_validate_task_state, error : lexique_message.select_error},
    {field : "job_id" , validate : m_validate_empty_fields, error : lexique_message.empty_error },
    {field : 'index' , validate : m_validate_empty_fields, error : lexique_message.empty_error},
    {field : 'index' , validate : m_validate_number, error : lexique_message.numeric_error}
]
const document_claim_rules : i_claim_rules[] = [
    { field :'recipient_email' , validate : m_validate_empty_fields, error : lexique_message.empty_error},
    { field :'recipient_email', validate : m_validate_email, error : lexique_message.email_error },
    { field :'recipient_name', validate : m_validate_empty_fields, error : lexique_message.empty_error },
    // { field :'recipient_name', validate : m_validate_str, error : lexique_message.special_error },
    { field :'insurance', validate : m_validate_empty_fields, error : lexique_message.empty_error },
    { field :'folder', validate : m_validate_empty_fields, error : lexique_message.empty_error },
    { field :'police', validate : m_validate_empty_fields, error : lexique_message.empty_error },
    { field :'template_id', validate : m_validate_empty_fields, error : lexique_message.empty_error },
]
export { customer_rules_completed, customer_rules , contact_rules , config_rules , profil_password_rules , profil_rules , user_rules,
     planner_rules , car_rules , registration_rules , insurances_rules , job_rules , task_rules , document_claim_rules , licence_rules }