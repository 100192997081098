/*
 * Date: 2024
 * Description: Img rotator for customer claims inputs
 * Author: Philippe Leroux @ Skitsc
 */


import React, { useState } from 'react';
import { Grid, Box, Typography, IconButton } from '@mui/material';
import { ExpandMore as ExpandMoreIcon, ExpandLess as ExpandLessIcon, RotateLeft , RotateRight , ArrowBack , ArrowForward } from '@mui/icons-material';
import { i_claim_inputs } from '../../../interfaces/claim.interface';
const api_url = process.env.REACT_APP_API_URL
const PhotoRotator = (props : i_claim_inputs) => {
    const [hide, setHide] = useState<boolean>(true);
    const [current_index, setCurrentIndex] = useState<number>(0);
    const [rotation_angle, setRotationAngle] = useState(0); 
    const [image_error, setImageError] = useState(false); 

    //Heres we could just pass this threw props if we need to use this component elsewhere
    const images = [
        `${api_url}/webapi/uploads/newclaim/${props.row.insurance.path[0]}`,
        `${api_url}/webapi/uploads/newclaim/${props.row.registration.path[0]}`,
        `${api_url}/webapi/uploads/newclaim/${props.row.licence.path[0]}`,
        `${api_url}/webapi/uploads/newclaim/${props.row.licence.path[1]}`
    ];

    const rotateLeft = () =>   setRotationAngle((prevAngle) => prevAngle - 90); 
    const rotateRight = () => setRotationAngle((prevAngle) => prevAngle + 90);  
    const goNext = () => {
        if (current_index < images.length - 1) {
            setCurrentIndex(current_index + 1);
            if(image_error)setImageError(false)
        }
    }

    const goPrev = () => {
        if (current_index > 0){
            setCurrentIndex(current_index - 1);
            if(image_error)setImageError(false)
        } 
    };
    const handleImageError = () => { setImageError(true); };
    const handleImageLoad = () => { setImageError(false); };
    const handleClick = (image : string) => { window.open(image, '_blank'); };
    const img_hover = {'&:hover': {  transform: `rotate(${rotation_angle}deg) scale(1.05)`, boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)', cursor: 'pointer' }}
    return (
        <Grid container spacing={2} mt={4}>
            <Grid item xs={2}>
                <Typography sx={{ fontWeight: 'bold' }}>
                    Assets
                    <IconButton onClick={() => setHide(!hide)}>
                        {hide ? <ExpandMoreIcon /> : <ExpandLessIcon />}
                    </IconButton>
                </Typography>
                <Box sx={{ display : hide ? 'none' : 'block' , marginBottom: 2 }}>
                    <IconButton onClick={rotateLeft}>
                        <RotateLeft />
                    </IconButton>
                    <IconButton  onClick={rotateRight}>
                        <RotateRight />
                    </IconButton>
                </Box>
            </Grid>
            <Grid item xs={10}>
                <Box sx={{ display: hide ? 'none' : 'block', textAlign: 'center',  position: 'relative' ,maxWidth: '600px', margin: '0 auto' , minHeight : '350px'}}>
                    <Typography sx={{ marginBottom : '1vh' , fontWeight : 700 , fontSize : 20}}>{current_index === 0 ? 'Insurance' : current_index === 1 ? 'Registration' : current_index === 2 ? 'Licence front' : current_index === 3 ? 'Licence back' : 'Should not' }</Typography>
                    {!image_error ? (
                        <Box component={'img'} onClick={() => handleClick(images[current_index])} onLoad={handleImageLoad}  onError={handleImageError}  src={images[current_index]} alt={`Image ${current_index + 1}`} 
                        sx={ [ img_hover , { maxHeight: '350px', maxWidth: '100%', transform: `rotate(${rotation_angle}deg)`, transition: 'transform 0.3s ease', objectFit: 'contain'} ]} />
                    ) : (
                        <Typography sx={{ maxHeight: '600px', maxWidth: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', height: '350px', backgroundColor: '#f0f0f0', border: '1px solid #ccc', color: '#666', }} >
                            Images not found. Please check the uploaded images.
                        </Typography>
                    )}
                </Box>
                <Box sx={{ display : hide ? 'none' : 'flex' , justifyContent : 'space-around'}}>
                    <IconButton onClick={goPrev} disabled={ current_index === 0}>
                        <ArrowBack />
                    </IconButton>
                    <IconButton  onClick={goNext} disabled={current_index === images.length - 1 }>
                        <ArrowForward />
                    </IconButton>
                </Box>
            </Grid>
        </Grid>
    );
};

export default PhotoRotator;