


/*
 * Date: 2024
 * Description: Middleware for planner
 * Author: Philippe Leroux @ Skitsc
 */

//Interfaces
import { i_user , i_user_login, i_user_reset } from "../interfaces/user.interface";
import { t_method } from "../types/types";
import { i_job } from "../interfaces/jobs.interface";
import { i_cal_event } from "../interfaces/planner.interface";
import { i_customer, i_contact, t_customer_car_valid_type, i_customer_car, i_insurance, i_registration, i_customer_document, i_customer_document_rules } from "../interfaces/customer.interface";
import { i_claim_pdf, i_claim_rules, t_claim_fields } from "../interfaces/claim.interface";
import { i_app_config } from "../interfaces/setting.interface";
import { i_task, t_task_fields } from "../interfaces/task.interface";

//Constants
import { lexique_message } from "../utils/constant";

//Middlewares
import { m_validate_email ,m_validate_password ,m_validate_empty_fields, m_validate_str, m_validate_phone,} from "./utility.middleware"; 

//Rules
import { car_rules, config_rules, contact_rules, customer_rules, customer_rules_completed, insurances_rules, job_rules, licence_rules, planner_rules, profil_password_rules, profil_rules, registration_rules, task_rules, user_rules } from "./rules";

const m_validate_login = ( form : i_user_login , display : 'login' | 'reset' | 'recovery' | 'final' | 'register' ) : [ boolean , string , string ] => {
    if(!m_validate_email(form.email)) return [ false, lexique_message.email_error, 'email'  ];
    if(display === 'login' && !m_validate_password(form.password)) return [ false, lexique_message.password_error , 'password'];
    return [ true , '' , ''  ]
}
const m_validate_recovery_reset = ( form : i_user_reset ) : [ boolean , string , string ] => {
    if(!m_validate_password(form.password)) return [ false, lexique_message.password_error, 'password'  ];
    if(form.password !== form.confirm_password) return [ false, 'Passwords do not match', 'confirm_password'  ];
    return [ true , '' , ''  ]
}
const m_validate_planner = ( form : i_cal_event ) : [ boolean , string , string ] => {
    for (const validation of planner_rules) {
        const { field, validate, error } = validation;
        if (!validate((form as i_cal_event)[field])) { return [false, error, field]; }
    }
    return [ true , '' , ''  ]
}
const m_validate_user = ( form : i_user , method : t_method ) : [ boolean , string , string ] => {
    for (const validation of user_rules) {
        const { field, validate, error } = validation;
        if(!validate((form as i_user)[field]))  return [false, error, field];
    }
    if(form.phone_two !== undefined && form.phone_two !== '') if(m_validate_phone(form.phone_two)) return [ false, lexique_message.phone_error, 'phone_two' ];
    if(method === 'POST'){
        if(!m_validate_password(form.password )) return [ false, lexique_message.password_error , 'password' ];
        if(form.confirm_password !== undefined && !m_validate_password(form.confirm_password )) return [ false, lexique_message.password_error , 'confirm_password' ];
        if(form.password !== form.confirm_password) return [ false, lexique_message.match_error, 'confirm_password' ];
    }
    return [ true , '' , ''  ]
}
const m_validate_profil_information = ( form : i_user ) : [ boolean , string , string ] => {
    for (const validation of profil_rules) {
        const { field, validate, error } = validation;
        if(!validate((form as i_user)[field]))  return [false, error, field];
    }
    if(form.phone_two !== undefined && form.phone_two !== '') if(m_validate_phone(form.phone_two)) return [ false, lexique_message.phone_error, 'phone_two' ];
    return [ true , '' , ''  ]
}
const m_validate_profil_passwords = ( form : i_user ) : [ boolean , string , string ] => {
    for (const validation of profil_password_rules) {
        const { field, validate, error } = validation;
        if(!validate((form as i_user)[field]))  return [false, error, field];
    }
    if(form.old_password !== undefined && form.old_password !== form.password) return [ true, "Password does not match..", 'confirm_password' ];
    return [ true , '' , ''  ]
}
const m_validate_uploads = ( uploads : any[] , assets : string[] ) : [ boolean , string , string ] => {
    //Some validations at another level here
    return [ true , '' , ''  ]
}
const m_validate_app_config = ( form : i_app_config ) : [ boolean, string, string ] => {
    for (const validation of config_rules) {
        const { field, validate, error } = validation;
        if(!validate((form as i_app_config)[field]))  return [false, error, field];
    }
    return [ true , '', '' ]
}
const m_validate_contacts = ( form : i_contact ) : [ boolean, string , string ] => {
    for (const validation of contact_rules) {
        const { field, validate, error } = validation;
        if(!validate((form)[field]))  return [false, error, field];
    }
    return [ true , '' , '' ]
}
const m_validate_customer = ( form : i_customer, method : t_method ) : [ boolean, string, string ] => {
  if(form.complete){
    for (const validation of customer_rules_completed) {
      const { field, validate, error } = validation;
      
        if(!validate((form as i_customer)[field]))  return [false, error, field];
        if(field === "zip"){
            //Licences validation here...
        }
    }
  }else{
    for (const validation of customer_rules) {
      const { field, validate, error } = validation;
      if(!validate((form as i_customer)[field]))  return [false, error, field];
    }
  }
    return [ true , '' , '' ]
}
const m_validate_customer_document = ( form : i_customer_document, method : t_method ) : [ boolean, string, string ] => {
    const validation_rules_completed : i_customer_document_rules[] = [ { field: "signature", validate: m_validate_empty_fields, error: lexique_message.empty_error, }, ]
    const validation_rules: i_customer_document_rules[] = [
        { field : "title", validate : m_validate_empty_fields, error : lexique_message.empty_error},
        { field : "title", validate : m_validate_str, error : lexique_message.str_error},
        { field : "customer_id", validate : m_validate_empty_fields, error : lexique_message.empty_error},
    ];
    if(form.signature){
        for (const validation of validation_rules_completed) {
            const { field, validate, error } = validation;
            if(!validate((form as i_customer_document)[field]))  return [false, error, field];
        }
    }else{
        for (const validation of validation_rules) {
            const { field, validate, error } = validation;
            if (!validate((form as i_customer_document)[field])) return [false, error, field];
        }
    }
    return [ true , '' , '' ]
}
const m_validate_claim_customer = ( form : any, step : number , files : number ) : [ boolean, string, string ] => {
    if(step === 0){
        for (const validation of customer_rules_completed) {
            const { field, validate, error } = validation;
            if(!validate((form as any)[field]))  return [false, error, field];
        }
    }
    if(step === 1){
        for( var i : number = 0; i < form.contact_array.length; i++){
            for (const validation of contact_rules) {
                const { field, validate, error } = validation;
                if(!validate((form.contact_array as any)[field]))  return [false, error, field];
            }
        }
    }
    if(step === 2){
        if(files !== 2) return [false, "You must upload 2 files for licence", 'files'];
        for (const validation of licence_rules) {
            const { field, validate, error } = validation;
            if(!validate((form as any)[field]))  return [false, error, field];
        }
    }
    if(step === 3){
        for (const validation of car_rules){
            const { field, validate, error } = validation;
            if(!validate((form as any)[field]))  return [false, error, field];
        }
    }
    if(step === 4){
        if(files !== 1) return [false, "You must upload 2 files for car registration", 'files'];
        for (const validation of registration_rules) {
            const { field, validate, error } = validation;
            if(!validate((form as any)[field]))  return [false, error, field];
        }
    }
    if(step === 5){
        if(files !== 1) return [false, "You must upload 1 files for car insurances", 'files'];
        for (const validation of insurances_rules) {
            const { field, validate, error } = validation;
            if(!validate((form as any)[field]))  return [false, error, field];
        }
    }
    return [ true , '' , '' ]
}
  const m_validate_customer_car = ( form : any , part : t_customer_car_valid_type) : [ boolean, string, string ] => {
    if(part === 'car'){
        for (const validation of car_rules) {
            const { field, validate, error } = validation;
            if(!validate((form as i_customer_car)[field]))  return [false, error, field];
        }
    }
    if(part === 'registration'){
        for (const validation of registration_rules) {
            const { field, validate, error } = validation;
            if(!validate((form as i_registration)[field]))  return [false, error, field];
        }
    }
    if(part === 'insurance'){
        for (const validation of insurances_rules) {
            const { field, validate, error } = validation;
            if(!validate((form as i_insurance)[field]))  return [false, error, field];
        }
    }
    return [true, '', ''];
  }
  const m_validate_job = ( form : i_job ) : [ boolean, string, string ] => {
    for (const validation of  job_rules) {
        const { field, validate, error } = validation;
        if (!validate((form)[field])) return [false, error, field];
    }
    return [ true , '' , '_id']
  }
  const m_validate_task = ( form : i_task ) : [ boolean, string, string ] => {
    const f_check_rows = ( body : i_task ) : [ boolean , string , t_task_fields] => {
        for (const validation of task_rules) {
            const { field, validate, error } = validation;
            if (!validate((body)[field])) return [false, error, field];
        }
        return [ true , '' , '_id']
    }
    return f_check_rows(form);
  }
  const m_validate_claim = ( form : i_claim_pdf ) : [ boolean, string, string ] => {
    const validation_rules : i_claim_rules[] = [
        { field :'recipient_email' , validate : m_validate_empty_fields, error : lexique_message.empty_error},
        { field :'recipient_email', validate : m_validate_email, error : lexique_message.email_error },
        { field :'recipient_name', validate : m_validate_empty_fields, error : lexique_message.empty_error },
        // { field :'recipient_name', validate : m_validate_str, error : lexique_message.special_error },
        { field :'insurance', validate : m_validate_empty_fields, error : lexique_message.empty_error },
        { field :'folder', validate : m_validate_empty_fields, error : lexique_message.empty_error },
        { field :'police', validate : m_validate_empty_fields, error : lexique_message.empty_error },
        { field :'template_id', validate : m_validate_empty_fields, error : lexique_message.empty_error },
    ]
    const f_check_rows = ( body : i_claim_pdf ) : [ boolean , string , t_claim_fields] => {
        for (const validation of  validation_rules) {
            const { field, validate, error } = validation;
            if (!validate((body)[field])) return [false, error, field];
        }
        return [ true , '' , 'testing']
    }
    return f_check_rows(form);
  }
export { m_validate_login ,m_validate_customer_document, m_validate_claim, m_validate_uploads, m_validate_profil_information, m_validate_profil_passwords , m_validate_task , m_validate_planner , m_validate_claim_customer, m_validate_user , m_validate_customer , m_validate_contacts , m_validate_customer_car , m_validate_recovery_reset , m_validate_app_config , m_validate_job}