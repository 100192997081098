/*
 * Date: 2024
 * Description: side bar menu for extra interactions
 * Author: Philippe Leroux @ skitsc
 */

//Modules
import { useContext, useState , useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Box, IconButton, Grid, Typography } from "@mui/material";
import { useNavigate  } from "react-router-dom";
import { Link } from "react-router-dom";
//Components
import SideMenuItem from "./sidebar.item";
import { MainContext, ThemeContext } from "../../context/context";
//Context

const SideBar = () => {
    const location = useLocation();
    const [ isCollapsed, setIsCollapsed ] = useState<boolean>(false);
    const [ width, setWidth ] = useState<string>(location.pathname.includes('settings') ? '0px' : '250px');
    const { user , config } = useContext(MainContext)
    const { mode } = useContext(ThemeContext)
    
    const navigate = useNavigate();
    useEffect(() => {
        const isSettingsPage = location.pathname.includes('settings');
        if (isSettingsPage) 
            setWidth('0px');
        else setWidth(isCollapsed ? '50px' : '250px')
    }, [location.pathname, isCollapsed]);
    const pre_path = process.env.REACT_APP_API_URL + '/webapi/public/config/'
    return (
    <>
            <Box sx={{ width: width, display: width === '0px' ? "none" : "flex", height: "100vh",flexDirection: "column",transition: "all 0.3s ease-in-out", borderRight : "1px solid #EAECF0"}} >
                <Box sx={[{ display : 'flex' , alignItems : 'center' , height : !isCollapsed ? '18vh' : '4.4vh' } , !isCollapsed ? { justifyContent : 'space-between' } : { justifyContent : "center" } , { transition : "all 0.3s ease-in-out" }]}  color={'primary'}>
                    <Grid container sx={{ display : 'flex' , justifyContent : 'center'}}>
                        {!isCollapsed && (
                            <Grid item xs={12} sx={{ display : 'flex' , justifyContent : 'center' , marginTop : '1vh' , marginLeft : '10%' , marginRight : '10%'}}>
                                    <Box component={'img'} src={ config.path !== '' ? pre_path + config.path  : './images/group.svg'} alt="logo" style={{ width : 'auto' , maxHeight: '75px' , maxWidth: '150px' }} />
                            </Grid>
                        )}
                        {!isCollapsed ? 
                        <>
                        <Grid item xs={9} sx={{ marginTop : '2vh' , display : 'flex' , justifyContent : 'flex-start'}}>
                            <Typography sx={{ paddingLeft : '0.5vh' , fontSize : 16 , fontWeight : 600}}>Gerant - </Typography>
                            <Typography sx={{ paddingLeft : '0.5vh', fontSize : 16, fontWeight : 700}}>18982872</Typography>
                        </Grid>
                        <Grid item xs={3} sx={{ marginTop : '1.5vh'}}>
                            <IconButton  onClick={() => setIsCollapsed(!isCollapsed)} title={"Menu"}>
                                <Box sx={{}} component={'img'} alt={"Menu button"} src={'/images/menu.svg'}></Box>
                            </IconButton>
                        </Grid>
                        <Grid item pb={2}>
                            <Typography sx={{ color : 'gray' , fontSize : 18 ,}}>{user.first_name + ' ' + user.last_name}</Typography>
                        </Grid>
                        </>
                        : 
                        <IconButton  onClick={() => setIsCollapsed(!isCollapsed)}>
                            <Box sx={{}} component={'img'} src={'/images/menu.svg'}></Box>
                        </IconButton>
                        }
                    </Grid>
                </Box>
                <Box sx={{ borderTop : '1px solid #EAECF0' , paddingTop : '2vh' , height : '66vh' , paddingLeft : !isCollapsed ? '0vh' : '0vh'}}>
                    <SideMenuItem mode={mode} title="overview" config={config} nav={navigate} path={location.pathname} Collapsed={isCollapsed} icon={<Box component={'img'} src={'/images/home_line.svg'}></Box>}/>
                    <SideMenuItem mode={mode} title="customers" config={config} nav={navigate} path={location.pathname} Collapsed={isCollapsed} icon={<Box component={'img'} src={'/images/batch_assign.svg'}></Box>}/>
                    <SideMenuItem mode={mode} title="jobs" config={config} nav={navigate} path={location.pathname} Collapsed={isCollapsed} icon={<Box component={'img'} src={'/images/layers_three_red.svg'}></Box>}/>
                    <SideMenuItem mode={mode} title="invoicing" config={config} nav={navigate} path={location.pathname} Collapsed={isCollapsed} icon={<Box component={'img'} src={'/images/bank_notes.svg'}></Box>}/> 
                    <SideMenuItem mode={mode} title="expense" config={config} nav={navigate} path={location.pathname} Collapsed={isCollapsed} icon={<Box component={'img'} src={'/images/dollar_account.svg'}></Box>}/>
                    <SideMenuItem mode={mode} title="banking" config={config} nav={navigate} path={location.pathname} Collapsed={isCollapsed} icon={<Box component={'img'} src={'/images/bank_account.svg'}></Box>} />
                    <SideMenuItem mode={mode} title="Zoho-Branch" config={config} nav={navigate} path={location.pathname} Collapsed={isCollapsed} icon={<Box component={'img'} src={'/images/graph_report.svg'}></Box>} />
                    <SideMenuItem mode={mode} title="timesheet" nav={navigate} config={config} path={location.pathname} Collapsed={isCollapsed} icon={<Box component={'img'} src={'/images/calendar.svg'} ></Box>} />
                    <SideMenuItem mode={mode} title="payroll" config={config} nav={navigate} path={location.pathname} Collapsed={isCollapsed} icon={<Box component={'img'} src={'/images/payroll.svg'}></Box>} />
                    <SideMenuItem mode={mode} title="links" config={config} nav={navigate} path={location.pathname} Collapsed={isCollapsed} icon={<Box component={'img'} src={'/images/link.svg'}></Box>} />
                    <SideMenuItem mode={mode} title="pay-calculator" nav={navigate} config={config} path={location.pathname} Collapsed={isCollapsed} icon={<Box component={'img'} src={'/images/file_check.svg'} ></Box>} />
                </Box>
                <Box sx={{ height : '17vh' , paddingLeft : !isCollapsed ? '0vh' : '0vh'}}>
                    {/* <SettingMenu mode={mode} title="settings" nav={navigate} path={''} Collapsed={isCollapsed} icon={<Box component={'img'} src={'/images/settings.svg'}></Box>} /> */}
                    <SideMenuItem mode={mode} title="settings" nav={navigate} config={config} path={location.pathname} Collapsed={isCollapsed} icon={<Box component={'img'} src={'/images/settings.svg'}></Box>} />
                    <SideMenuItem mode={mode} title="help" nav={navigate} config={config} path={location.pathname} Collapsed={isCollapsed} icon={<Box component={'img'} src={'/images/life_buoy.svg'}></Box>} />
                    <hr style={{ marginRight : '1vh' , border : "1px solid #EAECF0"}}/>
                    <IconButton component={Link} to={'/logout'} sx={{ paddingLeft : '20px'}}>
                        <Box sx={{}} component={'img'} src={'/images/logout.svg'}></Box>
                    </IconButton>
                </Box>
            </Box>
        
    </>
  );
};

export default SideBar;
