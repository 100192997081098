
/*
 * Date: 2023-08-14
 * Description: Backup Button can be invoked to get to inital page
 * Author: Philippe Leroux @ Skitsc
 */

//Modules
import { Box, IconButton, Tooltip } from "@mui/material"

//Icons
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const BackUpButton = ({ new_display , setDisplay , info } : any) => {

    return ( 
        <Box sx={{ alignSelf : 'center'}}>
            <Tooltip title={'Go back to ' + info}>
                <IconButton size="large" onClick={() => setDisplay(new_display)}>
                    <ArrowBackIcon sx={{ fontSize : 40}}></ArrowBackIcon>
                </IconButton>
            </Tooltip>
        </Box>
    )
}

export default BackUpButton