/*
 * Date: 2024
 * Description: Customer claim table with interactions
 * Author: Philippe Leroux @ Skitsc
 */

//Modules
import { Box , Typography } from "@mui/material";
import { useState , useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";

//Interfaces
import { i_claim_errors, i_claim_inputs , i_claim_props, i_zoho_sign_templates , i_claim_sign_document, i_zoho_sign_request } from "../../../interfaces/claim.interface";
import { i_tbl_header , i_table_v2_props , i_pagination_new, i_search_filter , i_socket_response } from "../../../interfaces/utility.interface";

//Utils
import { f_fetch } from "../../../api/fetch";
import { CheckEnv } from "../../../utils/utility";

//Components
import ClaimInputs from "./claim.documents.inputs";
import CircularUnderLoad from "../../utility/center.loader";
import BackUpButton from "../../utility/backup.button";

//Constants
import { default_claim, default_filter } from "../../../utils/constant";

//Middlewares
import { m_validate_claim } from "../../../validation/main.middleware";
import { m_validate_email , m_validate_empty_fields } from "../../../validation/utility.middleware";

//Context and State Management
import { MainContext } from "../../../context/context";

//Styles
import { header_row } from "../../../styles/tbl.styles";
import TblV2 from "../../table/table.v2";


const errors_claim : i_claim_errors = {
    template_id : '',
    insurance : '',
    folder : '',
    recipient_email : '',
    recipient_name : '',
    police : '',
}

const ClaimDocuments = ( props : i_claim_props ) => {
    const nav = useNavigate();
    const { HandleLogout } = useContext(MainContext)
    const [ data , setData ] = useState<i_zoho_sign_templates[]>([])
    const [ requests, setRequests ] = useState<i_claim_sign_document[]>([])
    const [ count , setCount ] = useState<number>(0)
    const [ button_loading , setButtonLoading ] = useState<[boolean , number]>([false , 0])
    const [ filter , setFilter ] = useState<i_search_filter>(default_filter)
    const [ loading , setLoading ] = useState<boolean>(false)
    const [ refresh , setRefresh ] = useState<number>(0)
    const [ errors , setErrors ] = useState<i_claim_errors>(errors_claim)
    const [ dev ] = useState<boolean>(CheckEnv);
    const S = props.socket
    useEffect(() => {
        S.removeAllListeners("zoho_sign_document");
        S.on('zoho_sign_document', ( output : i_socket_response ) => {
            if(output.type === 'Update'){
                const updateItem = ( ItemUpdated : i_claim_sign_document ) => {
                    const data_to_update = [ ...requests ] 
                    const updatedItems = data_to_update.map( ( item : i_claim_sign_document, i : number) => {
                        if (item._id === ItemUpdated._id) return ItemUpdated
                        else return item
                    })
                    setRequests(updatedItems)
                } 
                updateItem(output.item)
            }
            if(output.type === 'Add'){
                const addRow = ( ItemAdded : i_claim_sign_document ) => {
                    const data_to_update = [ ...requests ]
                    const objectExists = data_to_update.some(( obj : i_claim_sign_document ) => obj._id === ItemAdded._id);
                    if(!objectExists){
                        data_to_update.push(ItemAdded)
                        setRequests(data_to_update)    
                    }
                } 
                addRow(output.item)
            }
        })
      },[S , requests])
    useEffect(() => {
        const fetchTemplates = async () => {
            setLoading(true)
            const value = refresh > 0 ? '1' : '0'
            const res = await f_fetch("/zoho/sign/templates/"+value , 'GET' , true , null)
            if(res.type === 'Success'){
                if(res.data.length > data.length && refresh > 0) props.setApiError({ open : true, promise : { type : 'Success' , message : "New templates found !" , data : []} })
                setData(res.data)
                if(res.data.length > 0) {
                    props.setForm({...props.form, template_id : res.data[0].template_id}) 
                    const get = await f_fetch("/zoho/sign/requests/"+props.row._id+'/claim_customer' , 'GET' , true , null)
                    setRequests(get.data)
                }
            }else{
                if(res.type === 'Unauthorized') HandleLogout(nav)
            }
            setLoading(false)
        }
        fetchTemplates()
    }, [nav, HandleLogout , refresh])

    const handleSubmit = async() => {
        setLoading(true)
        const [ valid , err , field ] = await m_validate_claim( props.form )
        if(valid){
            const attentend_claim : i_claim_sign_document = {
                template_id : props.form.template_id,
                fields : {
                    CAS : props.form.insurance,
                    NDO : props.form.folder,
                    NPO : props.form.police,
                },
                recipient_email : props.form.recipient_email,
                recipient_name : props.form.recipient_name,
                testing: props.form.testing,
                _id : props.row._id,
                type : props.type
            }
            const res = await f_fetch("/zoho/sign/template" , 'POST' , true , attentend_claim)
            if(res.type === 'Success'){
                props.setForm({ ...default_claim , template_id : data[0].template_id })
                props.setApiError({ open : true, promise : res })
            }else{
                if(res.type === 'Unauthorized') HandleLogout(nav)
            }
            props.setApiError({ open : true, promise : res })
            setLoading(false)
        }else{
            setErrors({...errors, [field] : err })
        }
    }
    const handleRefresh = () => {
        setRefresh(refresh + 1)
    }
    const handleRowsPerPage = async( value : number) => {
        const new_filter : i_search_filter = { ...filter}
        new_filter.rows_per_page = value
        new_filter.page = 1
        setFilter(new_filter)
    }
    useEffect(() => {
        const F = props.form
        const E = errors
        if(E.recipient_email !== '' && m_validate_email(F.recipient_email)) setErrors({ ...E , recipient_email : ''})
        if(E.recipient_name !== '' && m_validate_empty_fields(F.recipient_name)) setErrors({...E , recipient_name : ''})
        if(E.insurance!== '' && m_validate_empty_fields(F.insurance)) setErrors({...E , insurance : ''})
        if(E.folder!== '' && m_validate_empty_fields(F.folder)) setErrors({...E , folder : ''})
        if(E.police!== '' && m_validate_empty_fields(F.police)) setErrors({...E , police : ''})
    }, [props.form, errors])
    const claim_headers : i_tbl_header[] = [
        { value : "Date added" , css : { ...header_row, } , portion : 3 , },
        { value : "Document name" , css : { ...header_row } , portion : 3 },
        { value : "Status" , css : { ...header_row } , portion : 2 },
        { value : "Sub status" , css : { ...header_row} , portion : 2},
        { value : 'Action', css : header_row, portion :2  }
    ]
    const pagination_props : i_pagination_new = {
        filter : filter,
        onPageChange: (event: React.ChangeEvent<unknown> , page : number) => {
          const new_filter = { ...filter }
          new_filter.page = page
          setFilter(new_filter)
        },
        count : requests.length,
        handleRowsPerPage : handleRowsPerPage,
        title : 'sign documents',
    }
    const table_v2_props : i_table_v2_props = {
        data : requests,
        title : 'No pending documents',
        loading : loading,
        headers : claim_headers,
        callback : () => {},
        setApiError : props.setApiError,
        row_model : "sign_document",
        pagination : pagination_props
    }
    const claim_inputs_props : i_claim_inputs = {
        form : props.form,
        errors : errors,
        templates : data,
        setForm : props.setForm,
        setErrors : setErrors,
        loading : loading,
        callback : handleSubmit,
        dev : dev,
        row : props.row,
        refresh : () => setRefresh(refresh + 1),
    }

    return (
        <Box>
            { loading ? <CircularUnderLoad type={'full'}/> :
                <Box>
                    {data.length > 0 ?
                        <Box>
                            <BackUpButton setDisplay={props.setDisplay} info={''} new_display={'table'} />
                            <Box sx={{ border: '1px solid #EAECF0' , padding : '20px' , maxWidth : '1400px' , marginLeft : 'auto' , marginRight : 'auto'}}>
                            { requests.length > 0 ?
                                <Box>
                                    <Typography variant={'h6'} sx={{ textAlign : 'center' , fontWeight : 700}}>Document list</Typography>
                                    <TblV2 {...table_v2_props} />
                                </Box>
                                :
                          
                                    <Typography variant={'h6'} sx={{ textAlign : 'center' , fontWeight : 700}}>No Document yet</Typography>
                               
                            } </Box>
                            <ClaimInputs {...claim_inputs_props} />
                        </Box>
                        :
                        <Box sx={{ marginTop : 5 , textAlign : 'center' , fontSize : 22 , fontWeight : 800}}> Please add templates first via api or interface ( not implemented yet ). </Box>
                    }
                </Box>
            }
        </Box>
    )

}


export default ClaimDocuments;