/*
 * Date: 2024
 * Description: User page
 * Author: Philippe Leroux @ skitsc
 */

//Modules
import { useNavigate } from "react-router-dom"
import { Box , Modal, Typography } from "@mui/material"
import { useEffect, useState , useContext, ReactElement , ChangeEvent } from "react"

//Interfaces && types
import { i_user, i_user_errors, i_user_form} from "../../interfaces/user.interface"
import { i_header_props, i_tbl_header,  i_snack_alert , i_alert_props , i_socket_response, i_prompt_modal_props, i_initial_props , i_search_filter , i_top_table_bar_props , i_basic_search_props , i_table_v2_props , i_pagination_new } from "../../interfaces/utility.interface"
import { t_display, t_method } from "../../types/types"

//Utilities
import { empty_promise, empty_user , default_filter, empty_user_errors} from "../../utils/constant"
import { f_fetch } from "../../api/fetch"
import { delay , f_encode_query_data , CheckEnv } from "../../utils/utility"

//Components
import CircularUnderLoad from "../../components/utility/center.loader"
import TblV2 from "../../components/table/table.v2"
import Header from "../../components/utility/header"
import AlertDialog from "../../components/utility/alert"
import UserInputs from "../../components/settings/users/user.inputs"
import CustomerTop from "../../components/customers/card/customer.top"
import ModalPromptBody from "../../components/modal/modal.prompt"
import Footer from "../../components/utility/footer"
import SearchFilter from "../../components/utility/inputs/search.filter"
import TopTableBar from "../../components/utility/top.table.bar"

//Context
import { MainContext, ThemeContext } from "../../context/context"
import { SocketContext } from "../../context/socket.context"

//Middleware
import { m_validate_user } from "../../validation/main.middleware"
import { m_force_str, m_validate_email, m_validate_password, m_validate_phone, m_validate_str , m_validate_user_type , m_validate_opt_zip_code } from "../../validation/utility.middleware"

//Styles
import { header_row, tbl_boxing } from "../../styles/tbl.styles"


const Users = ( props : i_initial_props ) : ReactElement => {
    const nav = useNavigate()
    const socket = useContext(SocketContext)
    const { mode } = useContext(ThemeContext)
    const { HandleLogout } = useContext(MainContext)
    const [ loading , setLoading ] = useState<boolean>(true)
    const [ title_header, setTitleHeader ] = useState<string>("Manage application users")
    const [ display , setDisplay ] = useState<t_display>('table')
    const [ data , setData ] = useState<i_user[]>([])
    const [ dev ] = useState<boolean>(CheckEnv);
    const [ count , setCount ] = useState<number>(0)
    const [ filter , setFilter ] = useState<i_search_filter>(default_filter);
    const [ search, setSearch ] = useState<string>('')
    const [ open , setOpen ] = useState<boolean>(false)
    const [ modal_title , setModalTitle ] = useState<string>('')
    const [ modal_loading , setModalLoading ] = useState<boolean>(false)
    const [ target , setTarget ] = useState<i_user>( empty_user )
    //Inputs
    const [ form_values , setFormValues ] = useState<i_user>(empty_user)
    //Errors management
    const [ errors_values , setErrorsValues ] = useState<i_user_errors>(empty_user_errors)

    const [ api_error , setApiError ] = useState<i_snack_alert>({open : false , promise : empty_promise});

  //Sockets events
  useEffect(() => {
    socket.removeAllListeners("user");
    socket.on('user', ( output : i_socket_response ) => {
        if(output.type === 'Update'){
            const updateItem = ( ItemUpdated : i_user ) => {
                const data_to_update = [ ...data ] 
                const updatedItems = data_to_update.map( ( item : i_user , i : number) => {
                if (item._id === ItemUpdated._id) {
                    return ItemUpdated
                } else {
                return item
                }
                })
                setData(updatedItems)
            } 
            updateItem(output.item)
        }
        if(output.type === 'Add'){
            const addRow = ( ItemAdded : i_user ) => {
                const data_to_update = [ ...data ]
                const objectExists = data_to_update.some(( obj : i_user ) => obj._id === ItemAdded._id);
                if(!objectExists){
                    data_to_update.push(ItemAdded)
                    setData(data_to_update)    
                }
            } 
            addRow(output.item)
        }
    })
  },[socket , data])
    const handleCallbackEdit = ( row : i_user ) => {
        setTitleHeader("Edit User")
        const clean_user = { ...empty_user , ...row }
        clean_user.password = ""
        clean_user.confirm_password = ""
        handleTarget(clean_user)
        setDisplay('form')
    }
    const handleCallbackDisable = ( row : i_user ) => {
        setTarget(row)
        setOpen(true)
        let disabled_title : string = 'Are you sure to disable : '
        if(row.disabled === true) disabled_title = 'Are you sure to enable : '
        setModalTitle(disabled_title + row.first_name + ' ' + row.last_name)
    }
    const fetchDisable = async() => {
        setModalLoading(true)
        await delay(1000)
        const res = await f_fetch('/user/'+target._id , 'PATCH' , true , null)
        if(res.type !== 'Success'){
            setApiError({open : true , promise : res})
        }
        if(res.type === 'Unauthorized') HandleLogout(nav)
        setModalLoading(false)
        setOpen(false)
    }
    const handleTarget = ( row : i_user ) => {
        setFormValues(row)
    }
    const handleSubmitForm = async() => {
        var method : t_method = 'POST'
        if( form_values._id !== '') method = 'PUT'
        const [valid , msg , field] = m_validate_user(form_values , method)
        if(valid){
            const res = await f_fetch('/user' , method , true , form_values)
            if(res.type === 'Success'){
                handleTarget(empty_user)
                handleForm()
                setDisplay('table')
            }
            setApiError({open : true, promise : res});
        }else{
            setErrorsValues({...errors_values , [field] : msg})
        }
    }
    const handleForm = () => {
        handleTarget(empty_user)
        setDisplay('form')
        setErrorsValues(empty_user_errors)
    }
    useEffect(() => {
        //Fonction pour aller chercher les users filtrés
        const f_fetch_users = async () => {
            setLoading(true)
            await delay(200)
            const params = f_encode_query_data(filter)
            const res = await f_fetch("/users/filtered?"+params , 'GET' , true , null)
            if(res.type === "Success") {
                setData(res.data.users)
                setCount(res.data.count)
            }
            if(res.type === 'Unauthorized') HandleLogout(nav)
            setLoading(false)
        }
        f_fetch_users()
    },[nav , HandleLogout , filter])
    useEffect(() => {
        if(display === 'form' && form_values._id === '') {
            setTitleHeader("Add User")
            handleTarget(empty_user)
        }
        if(display === 'table'){
            setTitleHeader('Manage application users')
        }
    },[display , form_values._id])
    useEffect(() => {
        const E = errors_values
        const F = form_values
        if(E.first_name !== '' && m_force_str(F.first_name) === '') setErrorsValues({...E, first_name : ''})
        if(E.last_name!== '' && m_force_str(F.last_name) === '') setErrorsValues({...E, last_name : ''})
        if(E.email!== '' && m_validate_email(F.email)) setErrorsValues({...E, email : ''})
        if(E.nas !== '' && m_validate_opt_zip_code(F.nas)) setErrorsValues({...E, nas : ''})
        if(E.phone_one!== '' && m_validate_phone(F.phone_one)) setErrorsValues({...E, phone_one : ''})
        if(E.phone_two!== '' && F.phone_two === '') setErrorsValues({...E, phone_two : ''})
        if(E.phone_two!== '' &&m_validate_phone(F.phone_two)) setErrorsValues({...E, phone_two : ''})
        if(E.password!== '' && m_validate_password(F.password)) setErrorsValues({...E, password : ''})
        if(E.confirm_password!== '' && F.password === F.confirm_password) setErrorsValues({...E, confirm_password : ''})
        if(E.type !== '' && m_validate_user_type(F.type)) setErrorsValues({...E, type : ''})
        if(E.unit_number!== '' && m_validate_str(F.unit_number)) setErrorsValues({...E, unit_number : ''})
        if(E.door_number!== '' && m_force_str(F.door_number) === '') setErrorsValues({...E, door_number : ''})
        if(E.street!== '' && m_force_str(F.street) === '') setErrorsValues({...E, street : ''})
        if(E.city!== '' && m_force_str(F.city) === '') setErrorsValues({...E, city : ''})
        if(E.state!== '' && m_force_str(F.state) === '') setErrorsValues({...E, state : ''})
        if(E.zip!== '' &&  m_validate_opt_zip_code(F.zip) ) setErrorsValues({...E, zip : ''})
    },[form_values , errors_values])
    const handleSearch = (e :ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value
        setSearch(value)
    }
    const handleRow = (row : i_user , type : t_display) => {
        if(type === 'form') handleCallbackEdit(row)
        if(type === 'disable') handleCallbackDisable(row)
    }
    const handleAdd = () => {
        handleTarget(empty_user)
        setDisplay('form')
    }
    const handleRowsPerPage = async( value : number) => {
        const new_filter : i_search_filter = { ...filter}
        new_filter.rows_per_page = value
        new_filter.page = 1
        setFilter(new_filter)
    }
    const form_props : i_user_form = {
        form : form_values,
        setForm : setFormValues,
        errors : errors_values,
        callback : handleSubmitForm,
        mode : mode,
        dev : dev,
        handleClose : () => setDisplay('table')
    }

    const header_props : i_header_props = {
        title : title_header,
        display : display,
        callback : () => setDisplay('table')
    }
    const pagination_props : i_pagination_new = {
        filter : filter,
        onPageChange: (event: React.ChangeEvent<unknown> , page : number) => {
          const new_filter = { ...filter }
          new_filter.page = page
          setFilter(new_filter)
        },
        count : count,
        handleRowsPerPage : handleRowsPerPage,
        title : 'Users'
    }
    
    const alert_props : i_alert_props = {
        event : api_error,
        handleClose : () => setApiError({ open : false, promise : empty_promise }),
        type : 'simple'
    }
    const modal_props : i_prompt_modal_props = {
        open : open,
        title : modal_title,
        handleClose : () => setOpen(false),
        callback : fetchDisable,
        loading : modal_loading,
        mode : mode,
        type : 'disable'
    }
    const top_table_props = {
        total : count,
        total_month : data.length,
        global_rating : 4.5,
        percent_month : 22,
        total_ratings : 522,
        percent_total : 15,
        high_rating : 201,
        mid_rating : 120,
        low_rating : 221,
        title : 'Users'
    }
    const search_input_props : i_basic_search_props = {
        search : search,
        handleSearch : handleSearch,
        placeholder : 'Search',
        loading : loading,
        callback : () => setFilter({...filter, search : search}),
        error_msg : ''
    }
    const table_tool_bar_props : i_top_table_bar_props = {
        title : 'Users',
        callback : () => handleAdd(),
        add : true,
        inputs : [<SearchFilter {...search_input_props} />]
    }
    const payroll_headers : i_tbl_header[] = [
        { value : "Date added" , css : { ...header_row, } , portion : 3 , },
        { value : "Name" , css : { ...header_row } , portion : 3 },
        { value : "Email" , css : { ...header_row } , portion : 2 },
        { value : "Phone" , css : { ...header_row} , portion : 2},
        { value : "Type" , css : { ...header_row } , portion : 1 },
        { value : 'Action', css : header_row, portion :1  }
    ]

    const table_v2_props : i_table_v2_props = {
        data : data,
        title : 'Please start by adding a new user',
        loading : loading,
        headers : payroll_headers,
        callback : handleRow,
        setApiError : setApiError,
        row_model : "user",
        pagination : pagination_props
    }
    return (
        <Box sx={{ }}>
            <Box sx={{ minHeight : '91vh'}}>
                { loading ? <CircularUnderLoad type={"full"} /> :
                    <Box>
                        {display === 'form' && <Header {...header_props}/> }
                        {display === 'table' && 
                            <Box>
                                <Box sx={{ display : 'flex' , justifyContent : "center" , paddingTop : '1vh'}}><Typography variant={'h4'}>User management</Typography></Box>
                                <CustomerTop {...top_table_props}/>
                                <Box sx={{ padding: '24px'}}>
                                    <TopTableBar {...table_tool_bar_props}/>
                                    <Box sx={tbl_boxing}>
                                        <TblV2 {...table_v2_props} />
                                    </Box>
                                </Box>
                            </Box>
                        }
                        {display === 'form' && 
                            <UserInputs {...form_props} />
                        }
                    </Box>
                }
                <AlertDialog {...alert_props}/>
                <Modal open={open} onClose={() => setOpen(false)} children={<ModalPromptBody {...modal_props}/>} />
            </Box>
            <Footer type={'center'}/>
        </Box>
    )
}


export default Users