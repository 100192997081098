/*
 * Date: 2024
 * Description: Customer Document claim inputs
 * Author: Philippe Leroux @ Skitsc
 */

//Modules
import { ChangeEvent } from "react"
import { Grid , Box , Typography , TextField, Switch , Button , MenuItem, Tooltip } from "@mui/material"

//Styles
import { container_grid , sub_title , input_base} from "../../../styles/form.styles"
import { i_claim_inputs, i_zoho_sign_templates } from "../../../interfaces/claim.interface"

//icons
import PhotoRotator from "./img.rotator";
const ClaimInputs = ( props : i_claim_inputs ) => {
    const E = props.errors
    const F = props.form
    const handleChange = (e : ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target
        props.setForm({...F, [name] : value})
    }
    const handleSwitch = () => {
        props.setForm({...F , testing : F.testing ? false : true  })
    }
    const handleSelect = (event : ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        // const { name, value } = event.target Here we could implement the template select feature here
    }
    return (
        <Box component={"form"} p={2} m={0} sx={{ marginBottom : '2vh'}} id={'customer_inputs'}>
            <Grid p={2} container sx={container_grid}>
                <Grid item xs={12} mb={2}>
                    <Typography variant="h4" sx={{ textAlign : 'center' , fontSize : 24 , fontWeight: 700}}>Send documents ( PDF ) for authorization </Typography>
                </Grid>
                <Grid item xs={2}>
                    <Typography sx={sub_title}>Selected Template :</Typography>
                </Grid>
                <Grid item xs={4}>
                    <TextField name={'document_type'} select sx={[input_base , { width : '300px'} ]} size="small" value={F.template_id} error={E.template_id!== ''} helperText={E.template_id}
                        onChange={handleSelect} autoComplete={props.dev? "off" : "new-password" }>
                            {props.templates.map((item : i_zoho_sign_templates) => (
                                <MenuItem key={item.template_id} value={item.template_id}>{item.template.template_name}</MenuItem>
                            ))}
                    </TextField>
                </Grid>
                <Grid item xs={6}>
                    <Tooltip title={'Trigger an api call to look for new zoho templates '}>
                        <Button variant="contained" onClick={() => props.refresh()}>Refresh</Button>
                    </Tooltip>
                </Grid>
                <Grid item xs={2}>
                    <Typography sx={sub_title}>Recipient Email :</Typography>
                </Grid>
                <Grid item xs={4}>
                    <TextField type={'email'} name={'recipient_email'} sx={[input_base , { width : '300px'} ]} size="small" value={F.recipient_email} error={E.recipient_email!== ''} helperText={E.recipient_email}
                        onChange={handleChange} autoComplete={props.dev ? "off" : "new-password" }/>
                </Grid>
                <Grid item xs={2}>
                    <Typography sx={sub_title}>Recipient Name :</Typography>
                </Grid>
                <Grid item xs={4}>
                    <TextField type={'text'} name={'recipient_name'} sx={[input_base , { width : '300px'} ]} size="small" value={F.recipient_name} error={E.recipient_name!== ''} helperText={E.recipient_name}
                        onChange={handleChange} autoComplete={props.dev ? "off" : "new-password" }/>
                </Grid>
                <Grid item xs={2}>
                    <Typography sx={sub_title}>Insurance Company :</Typography>
                </Grid>
                <Grid item xs={4}>
                    <TextField type={'text'} name={'insurance'} sx={[input_base , { width : '300px'} ]} size="small" value={F.insurance} error={E.insurance!== ''} helperText={E.insurance}
                        onChange={handleChange} autoComplete={props.dev ? "off" : "new-password" }/>
                </Grid>
                <Grid item xs={2}>
                    <Typography sx={sub_title}>Folder number :</Typography>
                </Grid>
                <Grid item xs={4}>
                    <TextField type={'text'} name={'folder'} sx={[input_base , { width : '300px'} ]} size="small" value={F.folder} error={E.folder!== ''} helperText={E.folder}
                        onChange={handleChange} autoComplete={props.dev ? "off" : "new-password" }/>
                </Grid>
                <Grid item xs={2}>
                    <Typography sx={sub_title}>Police Number :</Typography>
                </Grid>
                <Grid item xs={4}>
                    <TextField type={'text'} name={'police'} sx={[input_base , { width : '300px'} ]} size="small" value={F.police} error={E.police!== ''} helperText={E.police}
                        onChange={handleChange} autoComplete={props.dev ? "off" : "new-password" }/>
                </Grid>
                <Grid item xs={2}>
                    <Typography sx={sub_title}>Testing :</Typography>
                </Grid>
                <Grid item xs={4}>
                    <Switch checked={F.testing} onChange={() => handleSwitch()}></Switch>
                 </Grid>
                 <PhotoRotator {...props} />
                {/* <Grid item xs={2}>
                    <Typography sx={sub_title}>Assets <IconButton onClick={() => setHide( hide ? false : true)}>{hide ? <ExpandMoreIcon /> : <ExpandLessIcon /> }</IconButton></Typography>
                </Grid>
                 <Grid item xs={10}>
                    <Box sx={{ display : hide ? "none" : 'block'}}>
                        <Box component={'img'} sx={{ maxHeight : '600px' , maxWidth : '600px'}} src={ api_url + '/webapi/uploads/newclaim/' + props.row.insurance.path[0]}  />
                        <Box component={'img'} sx={{ maxHeight : '600px' , maxWidth : '600px'}} src={ api_url + '/webapi/uploads/newclaim/' + props.row.registration.path[0]}  />
                        <Box component={'img'} sx={{ maxHeight : '600px' , maxWidth : '600px'}} src={ api_url + '/webapi/uploads/newclaim/' + props.row.registration.path[1]}  />
                    </Box>
                 </Grid> */}
                 <Grid item xs={3}>

                 </Grid>
                 <Grid item xs={6} mt={4}>
                    <Button variant="contained" sx={{ fontSize : 18 , display : 'block' , marginLeft : 'auto' , marginRight : 'auto'}} onClick={() => props.callback()}>Send document</Button>
                 </Grid>
                 <Grid item xs={3}>

                </Grid>
                
            </Grid>
        </Box>
    )
}

export default ClaimInputs