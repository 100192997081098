
/*
 * Date: 2024
 * Description: Reusable table remastered
 * Author: Philippe Leroux @ Skitsc
 */


import { ReactElement } from "react";
import { Box } from "@mui/material";
import { i_table_v2_props } from "../../interfaces/utility.interface";


//Components
import PayRollRow from "../payroll/table/payroll.row";
import HeaderV2 from "./header.v2";
import JobsRows from "../jobs/table/jobs.rows";
import CustomerRow from "../customers/table/customer.rows";
import UserRow from "../settings/users/table/users.rows";
import TablePaginationV2 from "./pagination";
import TaskRows from "../jobs/table/task.rows";
import CustomerClaimRow from "../settings/claims/table/customer.claim";
import SignRequestsRow from "../settings/claims/table/sign.request";

const TblV2 = ( props : i_table_v2_props ) : ReactElement => {
    return (
        <Box>
            {props.data.length > 0 ?
                <Box>
                    <HeaderV2 headers={props.headers}/>
                  {  props.data.map(( row : any , index : number) => (
                    <Box key={index}>
                        { props.row_model === 'payroll' ? 
                            <PayRollRow row={row} {...props}/>
                        : props.row_model === 'job' ? 
                            <JobsRows row={row} {...props} ></JobsRows>
                        : props.row_model === 'customer' ?
                            <CustomerRow row={row} {...props}/>
                        : props.row_model === 'user' ?
                            <UserRow row={row} {...props}/>
                        : props.row_model === 'task' ?
                            <TaskRows row={row} {...props}/>
                        : props.row_model === 'customer_claim' ?
                            <CustomerClaimRow row={row} {...props}/>
                        : props.row_model === 'sign_document' ?
                            <SignRequestsRow row={row} {...props}/>
                        :
                        <></>}
                    </Box>
                    ))}
                    {props.pagination !== undefined &&
                        <TablePaginationV2 {...props.pagination} />
                    }
                </Box>
            :
            <Box sx={{ padding : "24px" , border : '1px solid gray' , fontSize : '22px', margin: '4%'}}>
                {props.title}
            </Box>
            }
        </Box>
    )

}

export default TblV2;