

//Modules
import { Box , Typography , Grid , Tooltip , IconButton , Menu , MenuItem } from "@mui/material"
import { useState } from "react";

//Icons
import RefreshIcon from '@mui/icons-material/Refresh';
import DownloadIcon from '@mui/icons-material/Download';
import VisibilityIcon from '@mui/icons-material/Visibility';
//Utils
import { f_timestamp_to_date } from "../../../../utils/utility"
import { i_table_v2_row_props } from "../../../../interfaces/utility.interface";
import { i_zoho_sign_request } from "../../../../interfaces/claim.interface";
import { f_fetch } from "../../../../api/fetch";

const SignRequestsRow = ( props : i_table_v2_row_props ) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null); 
    const [ loading , setLoading ] = useState(false);
    const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleMenuClose = () => {
        setAnchorEl(null);
    };
    const handleDownload = (filePath: string , row : i_zoho_sign_request) => {
        window.open(api_url + '/webapi/uploads/pdf/'+row._id + '/' +filePath , '_blank');
        handleMenuClose();
    };
   
    
    const handleRow = async(row : i_zoho_sign_request) => {
        setLoading(true)
        const res = await f_fetch("/zoho/sign/request/"+row.request_id , 'GET' , true , null);
        setLoading(false)
    }
    const api_url = process.env.REACT_APP_API_URL
    return (
        <Box sx={{ borderLeft : '1px solid #D3D3D3' , borderRight : '1px solid #D3D3D3' , borderBottom : '1px solid #D3D3D3'}}>
        <Grid container sx={{ padding : '6px'}}>
            <Grid item xs={props.headers[0].portion} sx={{ opacity : props.row.disabled ? 0.5 : 1}}>
                <Box sx={{ display : 'flex' , marginTop : '0.4vh' ,opacity : props.row.disabled ? 0.5 : 1 }}>
                    <Typography sx={{ fontSize : 14 }}>{f_timestamp_to_date(props.row.timestamp , 'long')}</Typography>
                </Box>
            </Grid>
            <Grid item xs={props.headers[1].portion} sx={{ alignSelf : 'center'}}>
                <Typography sx={{ alignSelf : 'center' , fontSize : 14 , opacity : props.row.disabled ? 0.5 : 1}}>{props.row.request.request_name }</Typography>
            </Grid>
            <Grid item xs={props.headers[2].portion} sx={{ alignSelf : 'center' , display : 'flex'}}>
                <Typography sx={{ alignSelf : 'center' , fontSize : 14 , opacity : props.row.disabled ? 0.5 : 1}}>{props.row.status}</Typography>
            </Grid>
            <Grid item xs={props.headers[3].portion} sx={{ alignSelf : 'center' , opacity : props.row.disabled ? 0.5 : 1}}>
               {props.row.request.actions[0].action_status}
            </Grid>
            <Grid item xs={props.headers[4].portion} sx={{ alignSelf : 'center' }}>
                <Tooltip title={"Sync with zoho api"}>
                    <IconButton sx={{ opacity : props.row.disabled ? 0.5 : 1}} onClick={() => handleRow(props.row)}><RefreshIcon  sx={{ animation: loading ? 'spin 1s linear infinite' : 'none'}} />  </IconButton>
                </Tooltip>
                {props.row.path.length > 0  && 
                    <>
                        <Tooltip title={"Download documents"}>
                            <IconButton sx={{ opacity : props.row.disabled ? 0.5 : 1}} onClick={handleMenuOpen}><DownloadIcon color={'primary'}/></IconButton>
                        </Tooltip>

                        <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose} >
                            {props.row.path.map((file : string, index : number) => (
                                <MenuItem key={index} onClick={() => handleDownload(file , props.row)} >
                                    {file}
                                </MenuItem>
                            ))}
                        </Menu>
                    </>
                }
                <Tooltip title={"View details"}>
                    <IconButton sx={{ opacity : props.row.disabled ? 0.5 : 1}} onClick={() => props.callback(props.row , 'view')}><VisibilityIcon color={'primary'}/></IconButton>
                </Tooltip>
            </Grid>
        </Grid>
    </Box>
    )
}


export default SignRequestsRow